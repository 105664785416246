import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Maybe, Query, SanityTestimonialConnection } from "@graphql-types";
import SEO from "@shared/seo";
import { LogoType, PageContext } from "@util/types";
import { Hero } from "@components";
import FooterSections from "@shared/footer/footerSections";
import { PageWidth, Section } from "@util/standard";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { BlocksContent, Image } from "@global";
import { em } from "@util/helper";

interface DataQuery extends Query {
  proTestimonial: Maybe<SanityTestimonialConnection> | undefined;
  goTestimonial: Maybe<SanityTestimonialConnection> | undefined;
}

interface Props extends PageProps {
  data: DataQuery;
  pageContext: PageContext;
}

interface TestimonialsSectionProps {
  testimonial: SanityTestimonialConnection;
  title: string;
}

const SectionStyled = styled(Section)`
  & + & {
    padding-top: 0;
  }
`;

const TestimonialsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  margin-top: 50px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    border-bottom: 1.5px solid ${colors.main};
    margin-top: 20px;
    margin-bottom: 50px;
  }
`;

const TestimonialWrapper = styled.div`
  padding: 70px 100px;
  width: 50%;
  border-top: 1.5px solid ${colors.main};

  &:nth-of-type(2n - 1) {
    border-right: 1.5px solid ${colors.main};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 50px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    && {
      width: 100%;
      padding: 50px 0;
      border-right: 0;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 25px;
`;

const ContentWrapper = styled.div`
  font-size: ${em(25, 16)};
  line-height: 1.25;
`;

const TestimonialsSection = ({ testimonial, title }: TestimonialsSectionProps) => {
  if (!testimonial?.nodes || testimonial.nodes.length < 1) return null;

  return (
    <SectionStyled>
      <PageWidth>
        <h2 className="h3">{title}</h2>
        <TestimonialsWrapper>
          {testimonial.nodes.map(item => {
            if (!item) return;
            const { testimony, name, image, packageCategory } = item;

            return (
              <TestimonialWrapper key={item._key}>
                {image &&
                  <ImageWrapper>
                    <Image data={image} isBackground />
                  </ImageWrapper>
                }
                <ContentWrapper>
                  <BlocksContent data={testimony} type={packageCategory as LogoType} margin="0 0 25px" />
                </ContentWrapper>
                <p>{name}</p>
              </TestimonialWrapper>
            );
          })}
        </TestimonialsWrapper>

      </PageWidth>
    </SectionStyled>
  );
}

export default function TestimonialLandingTemplate({ data, pageContext }: Props) {

  if (!data?.proTestimonial || !data?.goTestimonial || !data?.sanityLockedPage) return null;
  const { proTestimonial, goTestimonial, sanityLockedPage: { pageMeta } } = data;

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />
      <Hero data={pageMeta.pageHero} />
      {proTestimonial &&
        <TestimonialsSection testimonial={proTestimonial} title="HomeSell Pro Testimonials" />
      }
      {goTestimonial &&
        <TestimonialsSection testimonial={goTestimonial} title="HomeSell Go Testimonials" />
      }
      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
}

export const query = graphql`
  query TestimonialLandingQuery($pageId: String) {
    sanityLockedPage(_id: { regex: $pageId }) {
      pageMeta {
        ...sanityPageMeta
      }
    }
    proTestimonial: allSanityTestimonial(
      limit: 20
      sort: {order: DESC, fields: _updatedAt}
      filter: {packageCategory: {eq: "pro"}}
    ) {
      nodes {
        name
        packageCategory
        testimony {
          ...sanityBlockContent
        }
        image {
          ...sanityImageFullWidth
        }
      }
    }
    goTestimonial: allSanityTestimonial(
      limit: 20
      sort: {order: DESC, fields: _updatedAt}
      filter: {packageCategory: {eq: "go"}}
    ) {
      nodes {
        name
        packageCategory
        testimony {
          ...sanityBlockContent
        }
        image {
          ...sanityImageFullWidth
        }
      }
    }
  }
`;
